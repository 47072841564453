import { writable } from 'svelte/store';
import { browser } from '$app/env';

/* 
globalTopBanner = { 
    color = string
    message = string
    showPulseCircle = boolean (default false)
    showClose = boolean (default false)
}
*/

const storedGlobalTopBanner = JSON.parse(browser && localStorage.getItem('globalTopBanner')) || {};
export const globalTopBanner = writable(browser && storedGlobalTopBanner);
globalTopBanner.subscribe((val) => browser && (localStorage.globalTopBanner = JSON.stringify(val)));
